import React from "react";

function Explore() {
  return (
    <div className="grid w-full px-5 2xl:grid-flow-col xl:grid-flow-col lg:grid-flow-col 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 ">
      {/* card area */}
      <div className="h-full col-span-3 p-2 bg-white rounded-t-md lg:rounded-r-md 2xl:col-span-3 xl:col-span-3 lg:col-span-3 sm:col-span-5 md:col-span-5 lg:order-last md:rounded-none md:order-first">
        <div className="grid w-full h-full grid-flow-col grid-cols-6 gap-2 lg:grid-cols-6">
          <div className="relative col-span-2 overflow-hidden">
            <img
              className="object-cover w-full h-full transition-transform duration-300 transform 2xl:rounded-none xl:rounded-none lg:rounded-none md:rounded-l sm:rounded-l hover:scale-105"
              alt="img-1"
              src={require("../assets/images/Ellatrainride.jpg")}
            />
            <p className="absolute px-2 py-1 tracking-wider text-center text-white capitalize bg-black rounded 2xl:text-sm xl:text-sm lg:text-sm sm:text-xs md:text-xs bottom-4 left-4 bg-opacity-60 sm:-translate-x-1/2 sm:left-1/2">
              Ella train ride
            </p>
          </div>

          <div className="col-span-4">
            <div className="grid h-full grid-flow-row grid-rows-2 gap-2">
              <div className="grid grid-flow-col grid-cols-2 gap-2">
                <div className="relative col-span-1 overflow-hidden">
                  <img
                    className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-105"
                    alt="img-2"
                    src={require("../assets/images/Camping.jpg")}
                  />
                  <p className="absolute px-2 py-1 tracking-wider text-center text-white capitalize bg-black rounded 2xl:text-sm xl:text-sm lg:text-sm sm:text-xs md:text-xs bottom-4 left-4 bg-opacity-60 sm:-translate-x-1/2 sm:left-1/2">
                    Camping
                  </p>
                </div>
                <div className="relative col-span-1 overflow-hidden">
                  <img
                    className="object-cover w-full h-full transition-transform duration-300 transform rounded-tr-md hover:scale-105"
                    alt="img-3"
                    src={require("../assets/images/Hiking.webp")}
                  />
                  <p className="absolute px-2 py-1 tracking-wider text-center text-white capitalize bg-black rounded 2xl:text-sm xl:text-sm lg:text-sm sm:text-xs md:text-xs bottom-4 left-4 bg-opacity-60 sm:-translate-x-1/2 sm:left-1/2">
                    Hiking
                  </p>
                </div>
              </div>

              <div className="grid grid-flow-col grid-cols-2 gap-2">
                <div className="relative col-span-1 overflow-hidden">
                  <img
                    className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-105"
                    alt="img-4"
                    src={require("../assets/images/Surfing.webp")}
                  />
                  <p className="absolute px-2 py-1 tracking-wider text-center text-white capitalize bg-black rounded 2xl:text-sm xl:text-sm lg:text-sm sm:text-xs md:text-xs bottom-4 left-4 bg-opacity-60 sm:-translate-x-1/2 sm:left-1/2">
                    Surfing
                  </p>
                </div>
                <div className="relative col-span-1 overflow-hidden">
                  <img
                    className="object-cover w-full h-full transition-transform duration-300 transform rounded-br-md hover:scale-105"
                    alt="img-5"
                    src={require("../assets/images/Canyoning.jpg")}
                  />
                  <p className="absolute px-2 py-1 tracking-wider text-center text-white capitalize bg-black rounded 2xl:text-sm xl:text-sm lg:text-sm sm:text-xs md:text-xs bottom-4 left-4 bg-opacity-60 sm:-translate-x-1/2 sm:left-1/2">
                    Canyoning
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* explore area */}
      <div className="flex flex-col justify-center gap-3 p-5 bg-white 2xl:col-span-2 xl:col-span-2 lg:col-span-2 sm:col-span-5 md:col-span-5 rounded-b-md lg:rounded-l-md md:order-last lg:order-first">
        <div className="space-y-1 font-semibold tracking-wider uppercase sm:text-xl md:text-3xl 2xl:text-3xl xl:text-3xl lg:text-3xl">
          <p>Explore</p>
          <p>The Island</p>
        </div>

        <p className="font-normal text-left text-justify max-w-max md:text-md sm:text-sm 2xl:text-md xl:text-md lg:text-md">
          Every corner of Sri Lanka holds a hidden gem, a warm smile, and a
          story waiting to unfold. From lush landscapes and ancient temples to
          vibrant traditions and friendly faces, our island is rich with beauty
          and culture. Come, explore the heart of paradise, and let Sri Lanka
          share its timeless wonders and unforgettable moments with you.
        </p>

        <button className="px-4 py-2 mt-5 tracking-wider text-white capitalize rounded-sm hover:bg-black bg-primary sm:text-sm md:text-md 2xl:text-md xl:text-md lg:text-md w-fit">
          explore more
        </button>
      </div>
    </div>
  );
}

export default Explore;
