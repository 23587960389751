import React from "react";
import { FaRegClock, FaRegCalendar } from "react-icons/fa6";

function Packages() {
  const packages = [
    {
      id: 1,
      img: require("../assets/images/CyclingSriLanka.jpg"),
      title: "Cycling Sri Lanka",
      price: "1200",
      days: "5",
      date: "April 18, 2024",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      id: 2,
      img: require("../assets/images/FamilyLeisureTours.jpg"),
      title: "Family Leisure Tours",
      price: "1400",
      days: "6",
      date: "April 10, 2024",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      id: 3,
      img: require("../assets/images/Meditation&Yogatours.jpg"),
      title: "Meditation & Yoga tours",
      price: "1500",
      days: "5",
      date: "April 11, 2024",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      id: 4,
      img: require("../assets/images/SummerExperience.jpg"),
      title: "Summer Experience",
      price: "1600",
      days: "7",
      date: "April 13, 2024",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      id: 5,
      img: require("../assets/images/BeachTours.webp"),
      title: "Beach Tours",
      price: "1300",
      days: "8",
      date: "April 19, 2024",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      id: 6,
      img: require("../assets/images/AdventureTour.jpg"),
      title: "Adventure Tour",
      price: "1700",
      days: "9",
      date: "April 21, 2024",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
  ];

  return (
    <div className="px-5">
      <div className="grid items-center justify-center gap-6">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className="bg-white rounded-md shadow-md sm:w-80 md:w-96 2xl:w-96 xl:w-96 lg:w-96"
            >
              <div className="relative w-full h-64 overflow-hidden">
                <img
                  className="object-cover w-full h-full rounded-t-md"
                  alt={pkg.title}
                  src={pkg.img}
                />
                <p className="absolute top-0 px-2 py-1 text-sm font-medium tracking-wider text-black bg-gray-300 rounded-tl-md bg-opacity-70">
                  {pkg.price} USD / Pack
                </p>
              </div>
              <div className="flex flex-col items-center">
                <p className="py-3 font-semibold text-black capitalize sm:text-base md:text-lg 2xl:text-lg xl:text-lg lg:text-lg">
                  {pkg.title}
                </p>
                <p className="px-2 text-center text-black sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base">
                  {pkg.description}
                </p>
                <button className="px-4 py-1.5 mt-4 sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base text-white uppercase bg-primary rounded-md tracking-wider font-medium hover:text-black">
                  Book Now
                </button>
                <div className="flex w-full gap-5 px-3 py-1 mt-4 bg-gray-300 rounded-b-md">
                  <div className="flex items-center gap-1">
                    <FaRegClock />
                    <p className="font-medium capitalize sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base">
                      {pkg.days} days
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <FaRegCalendar />
                    <p className="font-medium capitalize sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base">
                      {pkg.date}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="tracking-wider text-center text-black cursor-pointer text-md hover:text-primary">
          Show More
        </p>
      </div>
    </div>
  );
}

export default Packages;
