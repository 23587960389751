import React from "react";
import {
  NavBar,
  Carousel,
  Destinations,
  Explore,
  Packages,
  Review,
  Footer,
  TrustSection,
  TitleDivider,
} from "../components/index";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <NavBar />
      <Carousel />

      <div className="flex-1">
        <TitleDivider title="Activities & Attractions" />
        <Explore />
        <TitleDivider title="Our Destinations" />
        <Destinations />
        <TitleDivider title="Inbound Tour packages" />
        <Packages />
        <TitleDivider title="Reviews of recently returned travelers" />
        <Review />
        <TrustSection />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
