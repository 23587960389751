import React, { useEffect, useState } from "react";
import { Search } from "../components/index";
import img1 from "../assets/images/img-1.jpg";
import img2 from "../assets/images/img-2.jpg";
import img3 from "../assets/images/img-3.jpg";

import { IoIosAirplane } from "react-icons/io";

const images = [img1, img2, img3];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full lg:h-screen xl:h-screen 2xl:h-screen overflow-hidden sm:h-[80vh] md:h-[90vh]">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
        >
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            className="object-cover w-full h-full"
          />

          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        </div>
      ))}

      <div className="absolute inset-0 flex flex-col items-center justify-center 2xl:pt-0 lg:pt-0 xl:pt-0 md:pt-36 sm:pt-10">
        {/* plane animation */}
        <div className="relative flex items-center justify-center font-bold text-center sm:text-lg md:text-3xl 2xl:text-3xl xl:text-3xl lg:text-3xl sm:mb-5 md:mb-10 2xl:mb-10 xl:mb-10 lg:mb-10">
          <div className="flex items-center justify-center logo-font sm:gap-0 md:gap-2 2xl:gap-2 xl:gap-2 lg:gap-2 sm:flex-col md:flex-col 2xl:flex-row xl:flex-row sm:py-0 lg:flex-row">
            <span className="text-yellow-500">
              <span className="sm:text-3xl md:text-5xl 2xl:text-5xl xl:text-5xl lg:text-5xl">
                S
              </span>
              PARK{" "}
              <span className="sm:text-3xl md:text-5xl 2xl:text-5xl xl:text-5xl lg:text-5xl">
                I
              </span>
              NTERNATIONAL
            </span>
            <span className="text-yellow-500">
              {" "}
              <span className="sm:text-3xl md:text-5xl 2xl:text-5xl xl:text-5xl lg:text-5xl">
                T
              </span>
              OURS &{" "}
              <span className="sm:text-3xl md:text-5xl 2xl:text-5xl xl:text-5xl lg:text-5xl">
                T
              </span>
              RAVELS (PVT) LTD
            </span>
          </div>
          <div className="absolute w-1/2 text-white plane-animation bottom-5">
            <IoIosAirplane size={20} />
          </div>
        </div>

        {/* Responsive Text Styling */}
        <div className="flex flex-col items-center gap-2 font-semibold tracking-wide text-center text-white uppercase lg:text-5xl xl:text-5xl 2xl:text-5xl sm:text-2xl md:text-4xl">
          <p>Step away from the routine and dive into a</p>
          <p>
            <span className="text-yellow-500">true travel experience </span>with
            us
          </p>
        </div>

        {/* Responsive Padding and Width for Search Component */}
        <div className="w-full xl:p-0 2xl:p-0 lg:p-0 sm:p-4">
          <Search />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
