import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { FaRegCalendar } from "react-icons/fa6";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";

const reviews = [
  {
    reviewerName: "John Doe",
    profilePhotoUrl: require("../assets/images/user.png"),
    rating: 2.5,
    text: "Great service and friendly staff!",
    time: "2 days ago",
  },
  {
    reviewerName: "Jane Smith",
    profilePhotoUrl: require("../assets/images/user.png"),
    rating: 4,
    text: "The place was fantastic, really enjoyed it!",
    time: "1 week ago",
  },
  {
    reviewerName: "Alice Brown",
    profilePhotoUrl: require("../assets/images/user.png"),
    rating: 5,
    text: "Highly recommend this! Will visit again.",
    time: "3 days ago",
  },
  {
    reviewerName: "Bob Wilson",
    profilePhotoUrl: require("../assets/images/user.png"),
    rating: 3,
    text: "It was okay, but there’s room for improvement.",
    time: "2 weeks ago",
  },
  {
    reviewerName: "Emily Davis",
    profilePhotoUrl: require("../assets/images/user.png"),
    rating: 4.5,
    text: "Excellent experience overall!",
    time: "5 days ago",
  },
  {
    reviewerName: "Michael Thompson",
    profilePhotoUrl: require("../assets/images/user.png"),
    rating: 4,
    text: "Very satisfied with the service.",
    time: "3 days ago",
  },
];

const ReviewCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(1); // Track number of items to display based on screen size

  // Adjust the number of items shown based on screen size
  useEffect(() => {
    const handleResize = () => {
      setItemsToShow(window.innerWidth < 640 ? 1 : 4); // 1 card on mobile, 4 on larger screens
    };
    handleResize(); // Set initial state based on screen size
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  // Get visible reviews based on current index and itemsToShow
  const visibleReviews = reviews.slice(
    currentIndex,
    currentIndex + itemsToShow
  );

  return (
    <div className="relative flex items-center justify-center w-full">
      {/* Previous Button */}
      <button
        onClick={handlePrevious}
        className="px-3 text-4xl text-gray-400 border-none bg-none hover:text-black"
      >
        &#10094;
      </button>

      {/* Carousel Slide Container */}
      <div className="flex max-w-full space-x-4 overflow-hidden">
        {visibleReviews.map((review, index) => (
          <div
            key={index}
            className="flex flex-col items-center w-64 p-6 text-center bg-white rounded-lg shadow-lg"
          >
            <img
              alt="user"
              src={review.profilePhotoUrl}
              className="w-16 h-16 mb-3 rounded-full"
            />
            <h5 className="text-lg font-semibold">{review.reviewerName}</h5>
            <div className="flex my-2">
              {Array.from({ length: 5 }, (_, i) => (
                <FaStar
                  key={i}
                  color={i < Math.round(review.rating) ? "#ffc107" : "#e4e5e9"}
                />
              ))}
            </div>
            <div className="flex items-center mb-2 text-sm text-gray-500">
              <FaRegCalendar className="mr-1" />
              <span>{review.time}</span>
            </div>

            <div className="flex justify-center mt-4 text-base">
              <span className="flex items-start">
                <RiDoubleQuotesL />
              </span>
              <p className="px-1 text-center">{review.text}</p>
              <span className="flex items-end">
                <RiDoubleQuotesR />
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Next Button */}
      <button
        onClick={handleNext}
        className="px-3 text-4xl text-gray-400 border-none bg-none hover:text-black"
      >
        &#10095;
      </button>
    </div>
  );
};

export default ReviewCarousel;
