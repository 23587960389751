import React, { useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

const Destinations = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      id: 1,
      img: require("../assets/images/Sigiriya.avif"),
      title: "Sigiriya",
      description:
        "Sigiriya is an ancient rock fortress located in the Matale District.",
    },
    {
      id: 2,
      img: require("../assets/images/Matara.jpg"),
      title: "Matara",
      description:
        "Matara is a major city located on the southern coast of Sri Lanka.",
    },
    {
      id: 3,
      img: require("../assets/images/Kandy.jpg"),
      title: "Kandy",
      description:
        "Kandy is a large city in central Sri Lanka, known for its scenic beauty.",
    },
    {
      id: 4,
      img: require("../assets/images/Colombo.jpg"),
      title: "Colombo",
      description:
        "Colombo is the commercial capital and largest city of Sri Lanka.",
    },
    {
      id: 5,
      img: require("../assets/images/Galle.jpg"),
      title: "Galle",
      description:
        "Galle is a city on the southwest coast of Sri Lanka, known for its fort.",
    },
  ];

  // Set the total number of slides and handle navigation
  const slideCount = slides.length;

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slideCount);
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slideCount) % slideCount);
  };

  // Determine the visible slides for the carousel
  const visibleSlides = [
    slides[(currentSlide - 1 + slideCount) % slideCount],
    slides[currentSlide],
    slides[(currentSlide + 1) % slideCount],
  ];

  return (
    <div className="relative flex items-center justify-center mx-5 shadow-md">
      {/* main image container */}
      <div className="relative w-full overflow-hidden bg-gray-300 sm:h-[85vh] md:h-[100vh] 2xl:h-96 xl:h-96 lg:h-96">
        <img
          className="object-cover w-full h-full rounded-md"
          src={slides[currentSlide].img}
          alt={slides[currentSlide].title}
        />
      </div>

      <div className="absolute flex gap-6 2xl:pr-5 xl:pr-5 lg:pr-5 2xl:-translate-y-1/2 xl:-translate-y-1/2 lg:-translate-y-1/2 2xl:right-0 xl:right-0 lg:right-0 2xl:top-1/2 xl:top-1/2 lg:top-1/2 sm:top-2/4 md:top-2/4">
        {/* card section */}
        {visibleSlides.map((slide, index) => (
          <div
            key={slide.id}
            className={`transform rounded-md shadow-lg transition ${
              index === 1 ? "scale-110 border-2 border-white" : "scale-100"
            }`}
          >
            <div className="relative w-48 h-56">
              <img
                className="object-cover w-full h-full rounded-md"
                src={slide.img}
                alt={slide.title}
              />

              <div className="absolute bottom-0 w-full bg-black rounded-b-md bg-opacity-65">
                <p className="py-2 font-semibold tracking-wider text-center text-white uppercase sm:text-lg md:text-xl 2xl:text-xl xl:text-xl lg:text-xl">
                  {slide.title}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* description section */}
      <div className="absolute text-white sm:px-4 md:px-4 2xl:pl-5 lx:pl-5 lg:pl-5 2xl:w-1/2 xl:w-1/2 lg:w-1/2 sm:text-center md:text-center 2xl:text-left xl:text-left lg:text-left 2xl:left-0 xl:left-0 lg:left-0 sm:top-16 md:top-16 2xl:top-auto xl:top-auto lg:top-auto">
        <p className="pb-10 font-semibold tracking-wider text-yellow-500 uppercase sm:text-xl md:text-4xl 2xl:text-4xl xl:text-4xl lg:text-4xl">
          Our Destinations
        </p>

        <p className="font-semibold tracking-wider uppercase sm:text-lg md:text-2xl 2xl:text-2xl xl:text-2xl lg:text-2xl">
          {slides[currentSlide].title}
        </p>
        <p className="pt-2 font-normal sm:text-sm md:text-lg 2xl:text-lg xl:text-lg lg:text-lg">
          {slides[currentSlide].description}
        </p>
      </div>

      {/* button area */}
      <div className="absolute bottom-0 pb-3 space-x-1">
        <button
          onClick={goToPrevSlide}
          className="p-2 text-white bg-black rounded-full bg-opacity-65"
        >
          <GrFormPrevious />
        </button>
        <button
          onClick={goToNextSlide}
          className="p-2 text-white bg-black rounded-full bg-opacity-65"
        >
          <GrFormNext />
        </button>
      </div>
    </div>
  );
};

export default Destinations;
