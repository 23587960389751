import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 600);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`w-full z-50 transition-all duration-300 ${
        isSticky ? "fixed top-0 shadow-lg" : "absolute bg-transparent"
      }`}
      style={{
        background: isSticky
          ? "linear-gradient(90deg, #000065, #000022)"
          : "transparent",
      }}
    >
      <div
        className={
          isSticky
            ? "flex flex-wrap items-center px-5 sm:py-4 md:py-4 2xl:py-2 xl:py-2 lg:py-2 sm:justify-between lg:justify-between 2xl:justify-between md:justify-center 2xl:gap-0 xl:gap-0 lg:gap-0 sm:gap-0 md:gap-3 xl:justify-between"
            : "flex flex-wrap items-center px-5 sm:py-4 md:py-4 2xl:py-4 xl:py-4 lg:py-4 sm:justify-between lg:justify-between 2xl:justify-between md:justify-center 2xl:gap-0 xl:gap-0 lg:gap-0 sm:gap-0 md:gap-3 xl:justify-between"
        }
      >
        <div className="flex items-center">
          <img
            className="lg:h-14 2l:h-14 xl:h-14 md:h-14 sm:h-12"
            src={require("../assets/images/SPARK ITT_Withour BG.png")}
            alt="logo"
          />
        </div>

        {/* Toggle Menu Button for Mobile */}
        <button
          onClick={toggleMenu}
          className="w-10 h-10 p-2 text-gray-500 rounded-lg md:hidden dark:text-white"
        >
          <span className="sr-only">Toggle Menu</span>
          {isOpen ? (
            <AiOutlineClose className="text-white cursor-pointer " />
          ) : (
            <AiOutlineMenu className="w-5 h-5 text-white cursor-pointer" />
          )}
        </button>

        {/* Mobile Menu */}
        <div
          className={`${
            isOpen
              ? "fixed inset-0 flex flex-col items-center justify-start pt-16 bg-gradient-to-top z-40"
              : "hidden"
          } md:flex md:w-auto md:static md:bg-transparent`}
        >
          {/* Close Button inside Mobile Menu (visible only on small screens) */}
          <button
            onClick={() => setIsOpen(false)}
            className="absolute text-white top-5 right-5 md:hidden"
          >
            <AiOutlineClose className="w-5 h-5" />
          </button>

          <ul className="flex flex-col items-center space-y-6 md:space-y-0 md:flex-row md:space-x-5">
            {[
              "Home",
              "Inbound",
              "Outbound",
              "Services",
              "Help center",
              "About Us",
              "Contact Us",
            ].map((item) => (
              <li key={item}>
                <NavLink
                  to="/"
                  className="block text-lg tracking-wider text-white capitalize sm:py-1 hover:bg-transparent"
                  onClick={() => setIsOpen(false)}
                >
                  {item}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
