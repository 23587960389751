import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";

function Footer() {
  return (
    <footer className="text-gray-300 bg-gradient-to-top">
      {/* First Row */}
      <div className="grid max-w-6xl grid-cols-1 gap-6 px-4 py-8 mx-auto mb-6 tracking-wider md:grid-cols-3">
        {/* Column 1 */}
        <div>
          <h3 className="mb-3">
            <img
              className="sm:w-28 md:w-36 2xl:w-36 xl:w-36 lg:w-36 md:m-0 sm:m-auto 2xl:m-0 xl:m-0 lg:m-0"
              src={require("../assets/images/SPARK ITT_Withour BG.png")}
              alt="logo"
            />
          </h3>
          <p className="py-1 text-base sm:text-center md:text-left 2xl:text-left xl:text-left lg:text-left">
            SPARK INTERNATIONAL TOURS & TRAVELS (PVT) LTD
          </p>
          <p className="text-base sm:text-center md:text-left 2xl:text-left xl:text-left lg:text-left ">
            Learn more about our mission, vision, and values. We are committed
            to delivering the best service possible.
          </p>
        </div>

        {/* Column 2 */}
        <div>
          <h3 className="mb-3 font-semibold sm:text-lg md:text-xl 2xl:text-xl xl:text-xl lg:text-xl sm:text-center md:text-left 2xl:text-left xl:text-left lg:text-left">
            Important Links
          </h3>
          <ul className="space-y-1 text-base cursor-pointer sm:text-center md:text-left 2xl:text-left xl:text-left lg:text-left">
            <li>Home</li>
            <li>Inbound</li>
            <li>Outbound</li>
            <li>Services</li>
            <li>Help Center</li>
          </ul>
        </div>

        {/* Column 3 - Contact Us */}
        <div>
          <h3 className="mb-3 font-semibold sm:text-lg md:text-xl 2xl:text-xl xl:text-xl lg:text-xl sm:text-center md:text-left 2xl:text-left xl:text-left lg:text-left">
            Contact Us
          </h3>
          <ul className="space-y-2 text-base sm:text-center md:text-left 2xl:text-left xl:text-left lg:text-left">
            <li className="flex sm:items-center sm:gap-2 2xl:gap-0 xl:gap-0 lg:gap-0 md:gap-0 2xl:items-start xl:items-start lg:items-start md:items-start sm:flex-col lg:flex-row md:flex-row 2xl:flex-row xl:flex-row">
              <FaMapMarkerAlt className="mr-3 sm:text-xl md:text-3xl 2xl:text-3xl xl:text-3xl lg:text-3xl" />
              Address: Apartment 9/7d, Crescat Residencies, No 75, Galle Rd,
              Colombo 00300
            </li>
            <li className="flex sm:items-center sm:gap-2 2xl:gap-0 xl:gap-0 lg:gap-0 md:gap-0 2xl:items-start xl:items-start lg:items-start md:items-start sm:flex-col lg:flex-row md:flex-row 2xl:flex-row xl:flex-row">
              <FaPhoneAlt className="mr-3 sm:text-xl md:text-xl 2xl:text-xl xl:text-xl lg:text-xl" />
              Phone: (+94) 77 756 8120
            </li>
            <li className="flex sm:items-center sm:gap-2 2xl:gap-0 xl:gap-0 lg:gap-0 md:gap-0 2xl:items-start xl:items-start lg:items-start md:items-start sm:flex-col lg:flex-row md:flex-row 2xl:flex-row xl:flex-row">
              <FaEnvelope className="mr-3 sm:text-xl md:text-xl 2xl:text-xl xl:text-xl lg:text-xl" />
              Email: tours@sparkitt99.com
            </li>
          </ul>
        </div>
      </div>

      {/* Second Row */}
      <div className="py-5 border-t border-gray-700">
        <div className="flex flex-col items-center justify-between max-w-6xl px-4 mx-auto md:flex-row">
          {/* Left Side - Copyright */}
          <p className="text-base text-center">
            &copy; {new Date().getFullYear()} SPARK INTERNATIONAL TOURS &
            TRAVELS (PVT) LTD. All rights reserved.
          </p>

          {/* Right Side - Social Media Links */}
          <div className="flex mt-4 space-x-4 md:mt-0">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white"
            >
              <FaFacebook size={20} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white"
            >
              <FaTwitter size={20} />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white"
            >
              <FaInstagram size={20} />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white"
            >
              <FaLinkedin size={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
