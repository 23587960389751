import React, { useState } from "react";
import { DatePicker, Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

const Search = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const suggestions = [
    "Romantic Honeymoon tours",
    "Family Leisure tours",
    "Leisure tours",
    "Leisure tours",
    "Cycling Sri Lanka",
    "Meditation & Yoga Tours",
    "Ramayana Tours",
    "Scenic Train Tide Tours",
    "Beach Tours",
    "Luxury Sri Lanka",
    "Unique Sri Lanka",
    "Destination wedding",
    "Pilgrimage Tours",
  ];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleDateChange = (dates) => setDateRange(dates);

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setShowSuggestions(false);
  };

  return (
    <div className="flex items-center justify-center w-full max-w-3xl p-4 mx-auto mt-8 bg-white rounded-md shadow-lg 2xl:flex-row xl:flex-row lg:flex-row sm:flex-col sm:gap-3 md:gap-3 2xl:gap-3 xl:gap-3 lg:gap-3">
      {/* Custom Search input with suggestions, without dropdown icon */}
      <div className="relative flex-1 lg:w-auto 2xl:w-auto xl:w-auto sm:w-full">
        <Input
          type="text"
          placeholder="Search for packages"
          value={searchQuery}
          onChange={handleSearchChange}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 150)}
          suffix={<SearchOutlined />}
          size="large"
        />
        {showSuggestions && (
          <div className="absolute z-10 w-full overflow-y-auto bg-white border border-gray-300 rounded-md shadow-md max-h-40">
            {suggestions
              .filter((suggestion) =>
                suggestion.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((suggestion, index) => (
                <div
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                >
                  {suggestion}
                </div>
              ))}
          </div>
        )}
      </div>

      {/* Date Range Picker */}
      <div className=" lg:w-auto sm:w-full">
        <RangePicker
          onChange={handleDateChange}
          value={dateRange}
          format="YYYY-MM-DD"
          placeholder={["From", "To"]}
          size="large"
          className="w-full"
        />
      </div>

      {/* Search button */}
      {/* <Button
        type="primary"
        icon={<SearchOutlined />}
        size="large"
        className="ml-2"
      >
        Search
      </Button> */}

      <button className="px-4 py-2 tracking-wider text-white rounded-md sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base 2xl:w-auto xl:w-auto lg:w-auto sm:w-full bg-primary">
        Search
      </button>
    </div>
  );
};

export default Search;
