import React from "react";

const TitleDivider = ({ title }) => {
  return (
    <div className="flex items-center justify-center w-full gap-5 px-5 py-10">
      <div className="flex items-center justify-center gap-2">
        <div className="w-16 border border-y-2 border-b-gray-300 "></div>
        <div className="w-2 border border-y-2 border-b-gray-300 "></div>
      </div>
      <p className="font-semibold tracking-wider text-center uppercase lg:text-xl xl:text-xl 2xl:text-xl md:text-xl sm:text-md">
        {title}
      </p>
      <div className="flex items-center justify-center gap-2">
        <div className="w-2 border border-y-2 border-b-gray-300 "></div>
        <div className="w-16 border border-y-2 border-b-gray-300 "></div>
      </div>
    </div>
  );
};

export default TitleDivider;
