import React from "react";
import {
  MdSecurity,
  MdThumbUp,
  MdSupportAgent,
  MdLocalOffer,
} from "react-icons/md";

function TrustSection() {
  return (
    <div className="grid w-full gap-4 p-5 mt-16 bg-gray-300 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-flow-row sm:grid-flow-row 2xl:grid-flow-col xl:grid-flow-col lg:grid-flow-col">
      {/* Secure Payments */}
      <div className="flex flex-col items-center justify-center col-span-1 tracking-wide">
        <MdSecurity className="sm:text-2xl md:text-4xl 2xl:text-4xl xl:text-4xl lg:text-4xl" />
        <p className="py-2 font-medium text-center capitalize sm:text-base md:text-lg 2xl:text-lg xl:text-lg lg:text-lg">
          Secure Payments
        </p>
        <p className="text-center sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base">
          We use safe payment methods to make payments safe and secure.
        </p>
      </div>

      {/* Verified Reviews */}
      <div className="flex flex-col items-center justify-center col-span-1 tracking-wide">
        <MdThumbUp className="sm:text-2xl md:text-4xl 2xl:text-4xl xl:text-4xl lg:text-4xl" />
        <p className="py-2 font-medium text-center capitalize sm:text-base md:text-lg 2xl:text-lg xl:text-lg lg:text-lg">
          Verified Reviews
        </p>
        <p className="text-center sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base">
          Trusted by thousands of happy travelers who share their experiences.
        </p>
      </div>

      {/* 24/7 Support */}
      <div className="flex flex-col items-center justify-center col-span-1 tracking-wide">
        <MdSupportAgent className="sm:text-2xl md:text-4xl 2xl:text-4xl xl:text-4xl lg:text-4xl" />
        <p className="py-2 font-medium text-center capitalize sm:text-base md:text-lg 2xl:text-lg xl:text-lg lg:text-lg">
          24/7 Support
        </p>
        <p className="text-center sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base">
          Our team is available around the clock to assist with your travel
          needs.
        </p>
      </div>

      {/* Best Price Guarantee */}
      <div className="flex flex-col items-center justify-center col-span-1 tracking-wide">
        <MdLocalOffer className="sm:text-2xl md:text-4xl 2xl:text-4xl xl:text-4xl lg:text-4xl" />
        <p className="py-2 font-medium text-center capitalize sm:text-base md:text-lg 2xl:text-lg xl:text-lg lg:text-lg">
          Best Price Guarantee
        </p>
        <p className="text-center sm:text-sm md:text-base 2xl:text-base xl:text-base lg:text-base">
          Get the best deals and lowest prices on all our travel packages.
        </p>
      </div>
    </div>
  );
}

export default TrustSection;
